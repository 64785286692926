import dayjs, { utc } from "dayjs";
import fromNow        from "dayjs/plugin/relativeTime";
import dynamic        from "next/dynamic";
import Link           from "next/link";
import { useState }   from "react";

import { getBaseURL, shortenNumber } from "_common/utils";
import Icon                          from "_components/atoms/icon";
import Image                         from "_components/atoms/image";
import Render                        from "_components/atoms/render";
import Typography                    from "_components/atoms/typography";
import ReportFollowModal             from "_components/molecules/reportFollowModal";
import SharePopper                   from "_components/molecules/sharePopper";

import useStyles from "./styles";

const VideoComments = dynamic ( () => import ( "_components/organisms/videoComments" ) );

dayjs.extend ( utc );
dayjs.extend ( fromNow );

const component = ( {
	data,
	watchNowClickHandler,
	onClickLike,
	isReacted,
	getShareLink,
	activeTab,
	feedType,
	onClickMenuOption,
	hideMenuOptions,
	onClickCommentIcon,
	isOpenCommentDrawer,
	onCloseCommentDrawer,
	isLive,
	fromRecommendations = false,
	hideActionButtons
} ) => {

	const [ showShareDrawer, setShowShareDrawer ] = useState ( false );

	const {
		userData
	} = data;

	const { classes } = useStyles ( { sponsoredAd: data?.sponsoredAd }, { name: "mobileBottomDetailsSection" } );

	const createdTS = data.createdAt ? dayjs ( dayjs.utc ( data.createdAt ) ).fromNow () : null;

	let reactionCount = 0;

	if ( isReacted ) {
		if ( !data?.reactions )
			reactionCount = 1;
		else
			reactionCount = data.reactions + 1;
	} else {
		reactionCount = data?.reactions ? data.reactions : "Like";
	}

	return (
		<>

			{
				isOpenCommentDrawer ? (
					<VideoComments
						key = { data?.id }
						commentCount = { data?.commentsCount || 0 }
						isOpen = { isOpenCommentDrawer }
						onClose = { onCloseCommentDrawer }
						videoId = { data?.id }
					/>
				) : null
			}

			<div className = { classes.container }>
				<div className = { classes.nameAndSectionContainer }>
					<Link
						className = { classes.profileIconAnchor }
						href = { !data?.sponsoredAd ? `/profile/${ userData?.id }` : null }
						prefetch = { false }
					>
						<Image
							className = { classes.profileImage }
							placeholder = "/images/circle-placeholder.webp"
							src = { userData?.photo }
							type = "mdpi"
						/>

					</Link>

					<div className = { classes.nameAndInfoContainer }>
						<Link
							href = { !data?.sponsoredAd ? `/profile/${ userData?.id }` : null }
							prefetch = { false }
						>
							<div className = { classes.nameContainer }>
								<Typography
									className = { classes.name }
									title = { userData.name }
								/>

								{userData.isCeleb ? (
									<Icon sameInBothTheme
										className = { classes.verifyIcon }
										name = "verifiedTickGrey"
									/>
								) : null}
							</div>
						</Link>

						<div className = { classes.streamInfo }>
							<Typography
								color = "secondary"
								title = { createdTS }
							/>

							{data?.views ? (
								<>
									<span className = { classes.ellipse }></span>

									<Typography
										color = "secondary"
										title = { `${ shortenNumber ( data.views ) } ${ ( data.isLive ) ? "watching" : "Views" }` }
									/>
								</>
							) : null}

							{
								data?.sponsoredAd ? (
									<Typography
										color = "secondary"
										title = "Sponsored"
									/>
								) : null
							}

						</div>
					</div>

				</div>

				<Typography
					className = { `${ classes.title }` }
					onClick = { watchNowClickHandler }
					title = { data.title }
				/>
			</div>

			<Render condition = { !fromRecommendations && !hideActionButtons }>

				<ActionButtons
					activeTab = { activeTab }
					data = { data }
					feedType = { feedType }
					getBaseURL = { getBaseURL }
					getShareLink = { getShareLink }
					hideMenuOptions = { hideMenuOptions }
					isLive = { isLive }
					isReacted = { isReacted }
					onClickCommentIcon = { onClickCommentIcon }
					onClickLike = { onClickLike }
					onClickMenuOption = { onClickMenuOption }
					reactionCount = { reactionCount }
					setShowShareDrawer = { setShowShareDrawer }
					shortenNumber = { shortenNumber }
					showShareDrawer = { showShareDrawer }
					userData = { userData }
				/>
			</Render>

		</>

	);
};

function ActionButtons ( {
	getBaseURL,
	getShareLink,
	setShowShareDrawer,
	showShareDrawer,
	onClickLike,
	isReacted,
	shortenNumber,
	reactionCount,
	onClickCommentIcon,
	isLive,
	data,
	hideMenuOptions,
	activeTab,
	feedType,
	userData,
	onClickMenuOption
} ) {
	const { classes } = useStyles ( {}, { name: "mobileBottomDetailsSection" } );

	return (
		<>
			<SharePopper useModal
				getSrc = { () => getBaseURL () + getShareLink ( {
					currentTime: 0
				} ) }
				handleClose = { () => setShowShareDrawer ( false ) }
				open = { showShareDrawer }
			/>

			<div className = { classes.actionBtnsContainer }>

				<div style = { { display: "flex", gap: "3rem" } }>
					<div className = { classes.actionBtnContainer }
						onClick = { onClickLike }
					>
						<Icon sameInBothTheme
							className = { classes.actionIcon }
							name = { isReacted ? "heartFilled" : "like" }
						/>

						<Typography className = { `${ classes.actionButtonText } ${ isReacted ? "primaryColor" : "" }` }
							color = { "secondary" }
							title = { shortenNumber ( reactionCount ) }
						/>
					</div>

					<div className = { classes.actionBtnContainer }
						onClick = { onClickCommentIcon }
					>
						<Icon sameInBothTheme
							className = { classes.actionIcon }
							name = { isLive ? "liveMessage" : "comment" }
						/>

						<Typography className = { classes.actionButtonText }
							color = "secondary"
							title = { isLive ? "Live Chat" : data.commentsCount ? data.commentsCount : "Comment" }
						/>
					</div>

					<div className = { classes.actionBtnContainer }
						onClick = { () => {
							setShowShareDrawer ( true );
						} }
					>
						<Icon sameInBothTheme
							className = { classes.actionIcon }
							name = "share"
						/>

						<Typography className = { classes.actionButtonText }
							color = "secondary"
							title = { data?.sharesCount ? data.sharesCount : "Share" }
						/>
					</div>
				</div>

				<div className = { classes.actionBtnContainer }>

					{hideMenuOptions ?
						null : (
							<ReportFollowModal
								activeTab = { activeTab } // need to find some other prop
								feedId = { data.id }
								feedType = { feedType }
								from = { "videoCard" }
								getSrc = { () => getBaseURL () + getShareLink ( { currentTime: 0 } ) }
								isFollowing = { userData?.isFollowing }
								onClickShareModal = { () => onClickMenuOption ( { type: "share" } ) }
								updateDeletedPost = { () => onClickMenuOption ( { type: "delete", data } ) }
								updateFeedList = { ( { type } ) => onClickMenuOption ( { type, data } ) }
								userId = { userData?.id }
								userName = { userData?.name }
							/>
						)}

				</div>
			</div>
		</>
	);
}
export default component;
