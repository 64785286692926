import { Overlay, Transition }         from "@mantine/core";
import { useTimeout }                  from "@mantine/hooks";
import dayjs                           from "dayjs";
import fromNow                         from "dayjs/plugin/relativeTime";
import utc                             from "dayjs/plugin/utc";
import { useEffect, useRef, useState } from "react";

import { storeEvent }                from "_analytics/index";
import { useGetDeviceType }          from "_common/hooks/global";
import { useSetHoverAnimation }      from "_common/hooks/player";
import { LIVESESSION, VIDEO }        from "_common/utils";
import { isReelCard }                from "_common/utils/reels";
import Icon                          from "_components/atoms/icon";
import Render                        from "_components/atoms/render";
import Typography                    from "_components/atoms/typography";
import { STREAM_TIER }               from "_components/pages/proPage/constants";
import { useGetCanWatchMoreContent } from "_jotai/compulsoryLogin";

import BottomDetailsSection       from "./bottomDetailsSection";
import Card                       from "./card";
import MobileBottomDetailsSection from "./mobileBottomDetailsSection";
import useStyles                  from "./styles";

dayjs.extend ( utc );
dayjs.extend ( fromNow );

const component = ( {
	data,
	onAdFinish,
	leftMostCard,
	rightMostCard,
	onRefresh,
	watchNowClickHandler,
	activeTab,
	onClickMenuOption,
	isLoggedIn,
	setRefreshedData,
	getShareLink,
	noAnimation,
	isMobile,
	hideMenuOptions,
	cardType,
	fromRecommendations,
	inProcessingItem
} ) => {

	const [ isHovering, setIsHovering ]                       = useState ( false );
	const canWatchMoreContent                                 = useGetCanWatchMoreContent ();
	const [ isAnimationStarted, setIsAnimationStarted ]       = useState ( false );
	const [ showProcessingOverlay, setShowProcessingOverlay ] = useState (
		inProcessingItem?.[cardType]?.feedId == data.id &&
    ( inProcessingItem?.[cardType]?.status === "FAILED" ||
      inProcessingItem?.[cardType]?.status === "TIMEOUT" ||
      inProcessingItem?.[cardType]?.status === "PENDING" ||
      inProcessingItem?.[cardType]?.status === "INITIATED" )
	);
	const setAnimationStarted                                 = useSetHoverAnimation ();
	const [ _noAnimation, setNoAnimation ]                    = useState ( noAnimation );

	useEffect ( () => {
		setShowProcessingOverlay (
			inProcessingItem?.[cardType]?.feedId == data.id &&
      ( inProcessingItem?.[cardType]?.status === "FAILED" ||
        inProcessingItem?.[cardType]?.status === "TIMEOUT" ||
        inProcessingItem?.[cardType]?.status === "PENDING" ||
        inProcessingItem?.[cardType]?.status === "INITIATED" ) );
	}, [ inProcessingItem?.[cardType]?.status ] );

	useEffect ( () => {
		setNoAnimation ( deviceType !== "desktop" || noAnimation );

		if ( inProcessingItem?.[cardType]?.status !== "COMPLETED" && inProcessingItem?.[cardType]?.feedId == data.id )
			setNoAnimation ( true );

	}, [ inProcessingItem?.[cardType]?.feedId ] );

	const deviceType = useGetDeviceType ();

	useEffect ( () => {
		if ( isHovering ) {
			setAnimationStarted ( isHovering );
			setTimeout ( () => {
				setIsAnimationStarted ( isHovering );
			}, 200 );
		} else {
			setIsAnimationStarted ( isHovering );
			setTimeout ( () => {
				setAnimationStarted ( isHovering );
			}, 200 );
		}
	}, [ isHovering ] );

	const divRef = useRef ();

	const handleGAEvent = ( feedType, feedId ) => {

		if ( feedType === LIVESESSION ) {
			storeEvent ( "Broadcast", "Hovered", feedId );
		}

		if ( feedType === VIDEO ) {
			storeEvent ( "Video", "Hovered", feedId );
		}

	};

	const startAnimationFunc = () => {
		handleGAEvent ( data.feedType, data.id );
		setIsHovering ( true );
	};

	const timing = 1000;

	const { start, clear } = useTimeout ( startAnimationFunc, timing );

	const xPosition   = leftMostCard ? ( divRef?.current?.offsetWidth / 10 ) : rightMostCard ? -( divRef?.current?.offsetWidth / 10 ) : 0;
	const isProStream = data.streamTier?.toString () === STREAM_TIER.PRO_STREAM;
	const isMidStream = data.streamTier?.toString () === STREAM_TIER.MID_STREAM;

	const { classes } = useStyles ( {
		isAnimationStarted,
		xPosition,
		activeTab,
		cardType,
		feedType: data.feedType
	} );

	const onMouseLeaveHandler = () => {
		if ( _noAnimation )
			return;

		clear ();
		setIsHovering ( false );
	};

	const onMouseEnter = () => {
		if ( noAnimation || !canWatchMoreContent || isProStream || isMidStream )
			return;

		start ();
	};

	const bottomSectionProps = {
		activeTab            : activeTab,
		fromRecommendations  : fromRecommendations,
		data                 : data,
		getShareLink         : getShareLink,
		isAnimationStarted   : isAnimationStarted,
		isLoggedIn           : isLoggedIn,
		onClickMenuOption    : onClickMenuOption,
		watchNowClickHandler : watchNowClickHandler,
		hideMenuOptions      : hideMenuOptions,
		cardType             : cardType
	};

	return (
		<div
			className = { classes.animationDiv }
			onMouseLeave = { onMouseLeaveHandler }
		>
			<div
				ref = { divRef }
				className = { classes.imageContainer }
				onMouseEnter = { onMouseEnter }
			>

				<Transition
					duration = { 200 }
					mounted = { showProcessingOverlay }
					timingFunction = "ease"
					transition = { "fade" }
				>
					{styles => {
						return (
							<div style = { styles }>
								<ProcessingStateOverlay
									contentType = { cardType }
									error = { inProcessingItem?.[cardType]?.status === "FAILED" || inProcessingItem?.[cardType]?.status === "TIMEOUT" }
									onClick = { onRefresh }
									setRefreshedData = { setRefreshedData }
								/>
							</div>
						);
					}}
				</Transition>

				<Card
					activeTab = { activeTab }
					cardType = { cardType }
					data = { data }
					isAnimationStarted = { isAnimationStarted }
					isProStream = { isProStream }
					leftMostCard = { leftMostCard }
					noAnimation = { noAnimation }
					onAdFinish = { onAdFinish }
					rightMostCard = { rightMostCard }
					watchNowClickHandler = { watchNowClickHandler }
				/>

			</div>

			{
				isMobile ? (
					<Render condition = { !isReelCard ( cardType ) }>
						<MobileBottomDetailsSection
							{ ...bottomSectionProps }
							hideMenuOptions = { showProcessingOverlay }
						/>
					</Render>
				)
					: (
						<BottomDetailsSection
							{ ...bottomSectionProps }
							hideMenuOptions = { showProcessingOverlay }
						/>
					)
			}

		</div>
	);
};

function ProcessingStateOverlay ( {
	error = false,
	onClick,
	setRefreshedData,
	contentType
} ) {

	const [ clicked, setClicked ] = useState ( false );

	const { classes } = useStyles ();

	useEffect ( () => {
		setTimeout ( () => {
			setClicked ( false );
		}, 3000 );
	}, [ onClick.loading ] );

	const _onClick = async () => {
		setClicked ( true );
		await onClick.fn ();
		setRefreshedData ( true );
	};

	return (
		<Overlay
			styles = { {
				root: {
					backgroundColor : "rgba(0,0,0,0.85) !important",
					zIndex          : "9 !important",
					borderRadius    : "1rem"
				}
			} }
		>
			<div
				className = { classes.overlayRoot }
			>
				<div className = { classes.overlayInner }>
					<div
						className = { classes.iconWrapper }
						data-clicked = { clicked ? true : undefined }
					>
						<Icon
							className = { classes.overlayIcon }
							name = { error ? "errorAlert" : "refresh" }
							onClick = { _onClick }
							sameInBothTheme = { true }
						/>
					</div>

					<Typography
						className = { classes.overlayTitle }
						title = { `Processing ${ contentType }...` }
					/>

					<Typography
						className = { classes.overlaySubtitle }
						color = { "secondary" }
						title = "This might take sometime check later."
					/>
				</div>
			</div>
		</Overlay>
	);
}
export default component;
