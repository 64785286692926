import Typography from "_components/atoms/typography";

import useStyles from "./styles";

const PostRemovedView = ( { title, cardType } ) => {
	const { classes } = useStyles ( { cardType }, { name: "postRemovedView" } );

	return (
		<div className = { classes.container }>
			<Typography
				className = { classes.title }
				title = { title }
			/>
		</div>
	);
};

export default PostRemovedView;